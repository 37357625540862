import React from 'react';
import PropTypes from 'prop-types';

const More = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7 9.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM7 2.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0ZM7 16.5a2 2 0 1 1 4 0 2 2 0 0 1-4 0Z" />
    </svg>
  );
};

More.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default More;
