import React from 'react';
import PropTypes from 'prop-types';

const ArrowUp = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 22"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m18 8 1.3-2.8L22 4l-2.8-1.3L18 0l-1.3 2.8L14 4l2.8 1.3L18 8ZM18 14l-1.3 2.8L14 18l2.8 1.3L18 22l1.3-2.8L22 18l-2.8-1.3L18 14ZM10.5 8.5 8 3 5.5 8.5 0 11l5.5 2.5L8 19l2.5-5.5L16 11l-5.5-2.5ZM9 12l-1 2.2L7 12l-2.2-1L7 10l1-2.2L9 10l2.2 1L9 12Z" />
    </svg>
  );
};

ArrowUp.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ArrowUp;
