import React from 'react';
import PropTypes from 'prop-types';

const Globe = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM.83 10a9.17 9.17 0 1 1 18.34 0A9.17 9.17 0 0 1 .83 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.83 10c0-.46.38-.83.84-.83h16.66a.83.83 0 0 1 0 1.66H1.67A.83.83 0 0 1 .83 10Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 10c.06 2.56.94 5.03 2.5 7.04A11.92 11.92 0 0 0 12.5 10 11.92 11.92 0 0 0 10 2.96 11.92 11.92 0 0 0 7.5 10ZM10 1.67l-.62-.57a13.58 13.58 0 0 0-3.55 8.92c.07 3.29 1.33 6.44 3.55 8.88a.83.83 0 0 0 1.24 0 13.58 13.58 0 0 0 3.55-8.92 13.58 13.58 0 0 0-3.55-8.88l-.62.57Z"
      />
    </svg>
  );
};

Globe.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Globe;
