import React from 'react';
import PropTypes from 'prop-types';

const AlertTriangle = ({
  className = '',
  fill = 'currentColor',
  size = 16
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M3.49987 22C2.99987 22 2.49987 21.9 1.99987 21.6C0.599865 20.8 0.099865 18.9 0.899865 17.5L9.39986 3.30002C9.69986 2.90002 9.99986 2.50002 10.3999 2.30002C11.0999 1.90002 11.8999 1.80002 12.6999 2.00002C13.4999 2.20002 14.0999 2.70002 14.5999 3.40002L22.9999 17.5C23.2999 18 23.3999 18.5 23.3999 19C23.3999 19.8 23.0999 20.6 22.4999 21.1C21.9999 21.7 21.2999 22 20.4999 22H3.49987ZM11.0999 4.40002L2.69987 18.5C2.39987 19 2.59986 19.6 3.09986 19.9C3.19986 20 3.39987 20 3.49987 20H20.3999C20.6999 20 20.8999 19.9 21.0999 19.7C21.2999 19.5 21.3999 19.3 21.3999 19C21.3999 18.8 21.3999 18.7 21.2999 18.5L12.8999 4.40002C12.5999 3.90002 11.9999 3.80002 11.4999 4.00002C11.2999 4.10002 11.1999 4.20002 11.0999 4.40002Z" />
      <path d="M11.9999 14C11.3999 14 10.9999 13.6 10.9999 13V9.00002C10.9999 8.40002 11.3999 8.00002 11.9999 8.00002C12.5999 8.00002 12.9999 8.40002 12.9999 9.00002V13C12.9999 13.6 12.5999 14 11.9999 14Z" />
      <path d="M11.9999 18C11.6999 18 11.4999 17.9 11.2999 17.7C11.0999 17.5 10.9999 17.3 10.9999 17C10.9999 16.9 10.9999 16.7 11.0999 16.6C11.1999 16.5 11.1999 16.4 11.2999 16.3C11.3999 16.2 11.4999 16.1 11.5999 16.1C11.7999 16 11.9999 16 12.1999 16C12.2999 16 12.2999 16 12.3999 16.1C12.4999 16.1 12.4999 16.1 12.5999 16.2C12.5999 16.2 12.6999 16.3 12.7999 16.3C12.8999 16.4 12.9999 16.5 12.9999 16.6C12.9999 16.7 13.0999 16.9 13.0999 17C13.0999 17.3 12.9999 17.5 12.7999 17.7C12.4999 17.9 12.2999 18 11.9999 18Z" />
    </svg>
  );
};

AlertTriangle.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default AlertTriangle;
