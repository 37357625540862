import React from 'react';
import PropTypes from 'prop-types';

const ReturnHome = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7 1.1a.7.7 0 0 0-.8 0L4 5.8l-.2.5v5.4L2 10.2a.7.7 0 1 0-.9 1l2.7 2.6c.2.3.6.3.9 0l2.7-2.7a.7.7 0 1 0-1-.9L5 11.7v-5l5.3-4.2 5.4 4.2v7a.7.7 0 0 1-.7.6H8s-.7 0-.7.7.7.7.7.7h7a2 2 0 0 0 2-2V6.3c0-.2 0-.4-.3-.5l-6-4.7Z"
      />
      <path d="M10 1.1v.2-.2Zm.7 0v.2-.2ZM4 5.8l-.1-.2.1.2ZM3.7 8h-.2.2Zm0 3.7-.2.2a.2.2 0 0 0 .4-.2h-.2ZM2 10.2l.2-.1-.2.1Zm-.9 0-.1-.1.1.1Zm0 1 .1-.2-.1.1Zm2.7 2.6-.2.1.2-.1Zm3.6-2.7-.2-.1.2.1Zm0-.9-.2.1.2-.1Zm-1 0-.1-.1.1.1ZM5 11.7h-.2a.2.2 0 0 0 .3.2l-.1-.2ZM5 8h.2H5Zm0-1.3-.1-.2-.1.2H5Zm5.3-4.2.2-.1h-.3l.1.1Zm5.4 4.2h.2l-.1-.2-.1.2Zm-.2 7.4-.2-.1.2.1ZM8 15.7v.2-.2Zm8.7-9.9.2-.2-.2.2ZM10 1.3h.6L11 1a.9.9 0 0 0-1.1 0l.2.3ZM4 6l6-4.7-.2-.3-6 4.6.2.4Zm-.1.3L4 6l-.2-.4c-.2.2-.3.5-.3.7h.4Zm0 1.7V6.3h-.4V8h.4Zm-.4 0v3.7h.4V8h-.4Zm.3 3.6L2.3 10l-.3.2L3.5 12l.3-.3ZM2.3 10A.9.9 0 0 0 1 10l.2.2H2l.3-.2ZM1 10c-.4.3-.4.8 0 1.2l.2-.3a.5.5 0 0 1 0-.7l-.2-.2Zm0 1.2 2.6 2.6.3-.2L1.3 11l-.2.3Zm2.6 2.6c.4.4 1 .4 1.2 0l-.2-.2H4l-.3.2ZM5 14l2.7-2.6-.3-.3-2.6 2.7.2.2Zm2.7-2.6c.4-.4.4-1 0-1.2l-.3.2c.2.2.2.5 0 .7l.3.3Zm0-1.2a.9.9 0 0 0-1.2 0l.3.2h.6l.3-.2Zm-1.2 0-1.5 1.5.2.3 1.6-1.6-.3-.2Zm-1.2 1.6V8h-.4v3.7h.4Zm-.4-5V8h.4V6.7h-.4Zm5.4-4.3L5 6.5l.2.3 5.4-4.1-.3-.3Zm5.6 4.1-5.3-4.1-.3.3 5.3 4.1.3-.3Zm0 7.2v-7h-.3v7h.4Zm-.2.6.3-.6h-.4s0 .2-.2.3l.3.3Zm-.6.2c.2 0 .5 0 .6-.2l-.3-.3-.3.1v.4Zm-4.7 0H15v-.4h-4.7v.4Zm-2.3 0h2.3v-.4H8v.4Zm-.5.5.1-.3.2-.1a.6.6 0 0 1 .2 0V14a.8.8 0 0 0-.1 0l-.3.1-.3.3-.2.5h.4Zm.5.7v-.2a.6.6 0 0 1-.2-.1l-.2-.1V15H7c0 .2 0 .4.2.5l.3.3a1 1 0 0 0 .4 0v-.1Zm2.3-.2H8v.4h2.3v-.4Zm4.7 0h-4.7v.4H15v-.4Zm1.3-.6c-.4.4-.8.6-1.3.6v.4c.6 0 1.1-.3 1.6-.7l-.3-.3Zm.5-1.2c0 .4-.2.9-.5 1.2l.3.3c.4-.4.6-1 .6-1.5h-.4Zm0-7.4v7.4h.4V6.3h-.4Zm-.2-.3.2.3h.4c0-.2-.1-.5-.3-.7l-.3.4Zm-6-4.7 6 4.7.3-.4-6-4.6-.3.3Z" />
    </svg>
  );
};

ReturnHome.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ReturnHome;
