import React from 'react';
import PropTypes from 'prop-types';

const Calendar = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.4 3c-.4 0-.8.2-.8.6v10.2c0 .4.4.7.8.7h10.8c.4 0 .8-.3.8-.7V3.6c0-.4-.4-.7-.8-.7H2.4ZM0 3.5c0-1.2 1-2.1 2.3-2.1h10.8c1.3 0 2.3 1 2.3 2.1v10.2c0 1.2-1 2.2-2.3 2.2H2.4C1 16 0 15 0 13.8V3.6Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9 0c.4 0 .8.3.8.7v3c0 .3-.4.7-.8.7S10 4 10 3.6V.7c0-.4.4-.7.8-.7ZM4.7 0c.4 0 .8.3.8.7v3c0 .3-.4.7-.8.7s-.8-.4-.8-.8V.7c0-.4.4-.7.8-.7ZM0 6.5c0-.4.4-.7.8-.7h14c.4 0 .7.3.7.7 0 .4-.3.8-.7.8H.8c-.4 0-.7-.4-.7-.8Z"
      />
    </svg>
  );
};

Calendar.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Calendar;
