import React from 'react';
import PropTypes from 'prop-types';

const Download = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.8 9.6a.8.8 0 0 1 .8.8v3.2a.8.8 0 0 0 .8.8h11.2a.8.8 0 0 0 .8-.8v-3.2a.8.8 0 0 1 1.6 0v3.2a2.4 2.4 0 0 1-2.4 2.4H2.4A2.4 2.4 0 0 1 0 13.6v-3.2a.8.8 0 0 1 .8-.8Z" />
      <path d="M3.434 5.834a.8.8 0 0 1 1.132 0L8 9.27l3.434-3.435a.8.8 0 1 1 1.132 1.132l-4 4a.8.8 0 0 1-1.132 0l-4-4a.8.8 0 0 1 0-1.132Z" />
      <path d="M8 0a.8.8 0 0 1 .8.8v9.6a.8.8 0 0 1-1.6 0V.8A.8.8 0 0 1 8 0Z" />
    </svg>
  );
};

Download.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Download;
