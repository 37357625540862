import React from 'react';
import PropTypes from 'prop-types';

const Search = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M11.3 2.4a8.4 8.4 0 1 0 0 16.8 8.4 8.4 0 0 0 0-16.8ZM.5 10.8a10.8 10.8 0 1 1 21.6 0 10.8 10.8 0 0 1-21.6 0Z" />
      <path d="M17.3 16.7c.4-.4 1.2-.4 1.7 0l5.2 5.3a1.2 1.2 0 0 1-1.7 1.6l-5.2-5.2c-.5-.4-.5-1.2 0-1.7Z" />
    </svg>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Search;
