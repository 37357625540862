import React from 'react';
import PropTypes from 'prop-types';

const ArrowLeft = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.7 1.7A1 1 0 0 0 7.3.3l-7 7a1 1 0 0 0 0 1.4l7 7a1 1 0 0 0 1.4-1.4L3.4 9H15a1 1 0 1 0 0-2H3.4l5.3-5.3Z"
      />
    </svg>
  );
};

ArrowLeft.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ArrowLeft;
