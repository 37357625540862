import React from 'react';
import PropTypes from 'prop-types';

const ChevronRight = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M3.3 15.7c-.4-.5-.4-1.2 0-1.7l6-6-6-6A1.1 1.1 0 1 1 5 .3l6.8 6.9c.5.4.5 1.2 0 1.6L5 15.7c-.5.4-1.2.4-1.7 0Z" />
    </svg>
  );
};

ChevronRight.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ChevronRight;
