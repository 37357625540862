import React from 'react';
import PropTypes from 'prop-types';

const Gear = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8.9 15.7H6.3l-.4-.1a.6.6 0 0 1-.2-.4l-.3-1.9a4.5 4.5 0 0 1-1.4-.8l-1.7.8a.6.6 0 0 1-.9-.3L.1 10.8v-.5c0-.2 0-.3.2-.4L2 8.7v-.3a8 8 0 0 1 0-1.1L.3 6a.7.7 0 0 1-.2-.4v-.5L1.4 3c.1-.2.2-.3.4-.3h.5l1.7.8a4.9 4.9 0 0 1 1.4-.8L5.7.8c0-.2 0-.3.2-.4l.4-.1H9l.4.1c.2.1.2.2.3.4l.3 2 .7.2.6.5 1.8-.8c.1 0 .3-.1.4 0 .2 0 .3.1.4.3l1.3 2.2v.5c0 .2 0 .3-.2.4l-1.6 1.1v.4a6.8 6.8 0 0 1 0 1.1l1.6 1.2.3.4v.5L13.7 13a.6.6 0 0 1-.8.3l-1.8-.8-.6.5a3 3 0 0 1-.7.3l-.3 1.9-.3.4-.4.1Zm-1.3-5.2c.7 0 1.3-.3 1.8-.7.4-.5.7-1.1.7-1.8s-.3-1.3-.7-1.8c-.5-.4-1.1-.7-1.8-.7s-1.3.3-1.7.7C5.4 6.7 5 7.3 5 8s.3 1.3.8 1.8c.4.4 1 .7 1.7.7Zm0-1.2c-.4 0-.7-.1-1-.4-.2-.2-.3-.5-.3-.9s.1-.7.4-1c.2-.2.5-.3 1-.3.3 0 .6.1.9.4.2.2.3.5.3.9s0 .7-.3 1c-.3.2-.6.3-1 .3Zm-.8 5.1h1.6l.3-2a4.6 4.6 0 0 0 2.2-1.4l2 1 .8-1.4-1.8-1.3A9 9 0 0 0 12 8a5.2 5.2 0 0 0-.1-1.3l1.8-1.3-.8-1.3-2 .9c-.3-.4-.6-.7-1-.9-.4-.2-.8-.4-1.2-.4l-.3-2.1H6.8l-.2 2A4 4 0 0 0 4.3 5l-2-1-.7 1.4 1.8 1.3A9 9 0 0 0 3.2 8v.7l.2.6-1.8 1.3.7 1.3 2-.9a4.8 4.8 0 0 0 2.3 1.3l.2 2.1Z" />
    </svg>
  );
};

export default Gear;

Gear.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};
