import React from 'react';
import PropTypes from 'prop-types';

const ChevronDown = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.33 3.33a1.14 1.14 0 0 1 1.62 0L8 9.38l6.05-6.05a1.14 1.14 0 0 1 1.62 1.62L8.8 11.81c-.45.44-1.17.44-1.62 0L.33 4.95a1.14 1.14 0 0 1 0-1.62Z" />
    </svg>
  );
};

ChevronDown.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ChevronDown;
