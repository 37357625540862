import React from 'react';
import PropTypes from 'prop-types';

const Paperclip = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10.5199 1.5919a2.0014 2.0014 0 0 0-1.4152.5862L2.978 8.3048a3.3356 3.3356 0 0 0 4.7172 4.7172l6.1267-6.1267a.6667.6667 0 1 1 .9428.9428L8.638 13.9648A4.6689 4.6689 0 1 1 2.0352 7.362L8.162 1.2353a3.3348 3.3348 0 1 1 4.7161 4.7161l-6.1333 6.1267a2.0008 2.0008 0 0 1-2.8295-2.8295L9.5755 3.595a.6667.6667 0 1 1 .9422.9434l-5.6597 5.653a.6674.6674 0 0 0 .9439.9439l6.1333-6.1267a2.0012 2.0012 0 0 0-1.4153-3.4167Z" />
    </svg>
  );
};

Paperclip.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Paperclip;
