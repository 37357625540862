import React from 'react';
import PropTypes from 'prop-types';

const MapPin = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 1.3a5.3 5.3 0 0 0-5.3 5.4c0 2 1.3 4 2.8 5.6a19.6 19.6 0 0 0 2.5 2.2 18.5 18.5 0 0 0 2.5-2.2c1.5-1.6 2.8-3.6 2.8-5.6a5.3 5.3 0 0 0-5.3-5.4Zm0 14-.4.6a7.1 7.1 0 0 1-.2-.2A19 19 0 0 1 4 13.2C2.5 11.6.8 9.2.8 6.7a6.7 6.7 0 1 1 13.4 0c0 2.6-1.7 4.9-3.2 6.5A21 21 0 0 1 7.9 16l-.4-.6Zm0 0 .4.6c-.3.1-.5.1-.8 0l.4-.6Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 5.3a1.3 1.3 0 1 0 0 2.7 1.3 1.3 0 0 0 0-2.7ZM4.8 6.7a2.7 2.7 0 1 1 5.4 0 2.7 2.7 0 0 1-5.4 0Z"
      />
    </svg>
  );
};

MapPin.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default MapPin;
