import React from 'react';
import PropTypes from 'prop-types';

const ArrowDown = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9 1a1 1 0 1 0-2 0v11.6L1.7 7.3A1 1 0 0 0 .3 8.7l7 7c.4.4 1 .4 1.4 0l7-7a1 1 0 0 0-1.4-1.4L9 12.6V1Z" />
    </svg>
  );
};

ArrowDown.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ArrowDown;
