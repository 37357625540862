import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from '@inertiajs/inertia-react';

const Button = props => {
  const {
    children,
    className,
    customClass,
    disabled,
    href,
    id,
    name,
    newWindow,
    onClick,
    size,
    style,
    to,
    type,
    value
  } = props;

  const classes = classNames({
    btn: true,
    [`btn-${size}`]: size,
    [`btn-${style}`]: style,
    [className || customClass]: className || customClass
  });

  if (to && !disabled) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <Link
        href={to}
        id={id}
        className={classes}
        onClick={onClick}
        target={newWindow ? '_blank' : null}
        rel={newWindow ? 'noopener noreferrer' : null}
      >
        {children}
      </Link>
    );
  } else if (href && !disabled) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={href}
        id={id}
        className={classes}
        onClick={onClick}
        target={newWindow ? '_blank' : null}
        rel={newWindow ? 'noopener noreferrer' : null}
      >
        {children}
      </a>
    );
  } else {
    return (
      <button
        onClick={onClick}
        id={id}
        className={classes}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
};

Button.defaultProps = {
  size: 'md',
  style: 'default',
  type: 'button'
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  customClass: PropTypes.string, // customClass prop is deprecated; use className instead
  disabled: PropTypes.bool,
  href: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  newWindow: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'full']),
  style: PropTypes.oneOf(['bright', 'primary', 'default', 'link', 'muted']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  value: PropTypes.string
};

export default Button;
