import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import poweredByGoogle from '../../../images/powered_by_google_on_white_hdpi.png';
import stringOffsetReplace from '../../../lib/stringOffsetReplace';
import { uniqBy, filter, isEmpty, isNil } from 'lodash';
import fuzzySearch from '../../../lib/fuzzySearch';
import { renderCoordinatesString, renderWithHighlights } from '../../utils';

class LocationAutocomplete extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      googleResults: [],
      geocodingResults: [],
      inputValue: props.inputValue,
      cachedPlaces: {},
      savedLocations: props.savedLocations,
      recentLocations: props.recentLocations,
      sessionToken: new google.maps.places.AutocompleteSessionToken(),
      unedited: true
    };

    this.inputRef = this.props.inputRef ?? React.createRef();

    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocoder = new google.maps.Geocoder();

    // for some reason the PlacesService requires a "map" instance, we'll just
    // give it a DOM element ¯\_(ツ)_/¯
    const mapStub = document.createElement('div');
    this.placesService = new google.maps.places.PlacesService(mapStub);

    this.placePredictionsCallback = this.placePredictionsCallback.bind(this);
    this.geocoderCallback = this.geocoderCallback.bind(this);
    this.performGeocode = this.performGeocode.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.runQueries = this.runQueries.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.state.inputValue > '') {
      this.runQueries(this.state.inputValue);
    }

    // This is here to initialize the autocomplete and pull data from google if
    // the server generated input has an initial place id.  It allows the caller
    // to have a fully initialized version of the input.
    // The third argument is to signal that this is the initial load causing the callback
    if (this.props.initialPlaceId) {
      this.onSelect(this.props.inputValue, this.initialItem(), true);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isInvalid !== this.props.isInvalid) {
      if (this.props.isInvalid) {
        this.inputRef.current?.setCustomValidity('invalid');
      } else {
        this.inputRef.current?.setCustomValidity('');
      }
    }

    if (
      this.props.onManualLocation &&
      this.props.value?.manual_location &&
      this.props.inputValue !== prevProps.inputValue
    ) {
      this.setState({
        inputValue: `${renderCoordinatesString(this.props.value)} (near ${
          this.props.value.description
        })`
      });
    }

    if (
      !this.props.value?.manual_location &&
      this.state.unedited &&
      this.props.inputValue !== this.state.inputValue
    ) {
      this.setState({ inputValue: this.props.inputValue });
    }

    if (
      this.props.value?.isProgrammaticallySet &&
      this.props.inputValue !== prevProps.inputValue
    ) {
      this.setState({ inputValue: this.props.inputValue });
    }
  }

  // Issue: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in the componentWillUnmount method.
  // Resolution: track mounting to prevent attempting to run functions when component is unmounted
  // Resolution: track mounting so we can return early from running function if we are unmounting
  // See: https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
  componentWillUnmount() {
    this._isMounted = false;
  }

  initialItem() {
    const found_item = this.state.savedLocations.find(
      item => item.place_id === this.props.initialPlaceId
    );
    if (found_item) {
      return found_item;
    }
    return { place_id: this.props.initialPlaceId };
  }

  items() {
    let allTheItems = [
      // Only show the first 3 items at a time
      ...this.state.savedLocations
        .map(item => {
          return {
            ...item,
            type: 'savedLocation',
            inputValue: item.saved_record
              ? item.description || ''
              : item.description
          };
        })
        .slice(0, 3)
    ];

    if (!this.props.onlySavedLocations) {
      allTheItems.push(
        // Only show the first 3 items at a time
        ...this.state.recentLocations
          .map(item => {
            return {
              ...item,
              type: 'recentLocation',
              inputValue: item.description
            };
          })
          .slice(0, 3),

        ...this.state.googleResults.map(item => {
          return {
            ...item,
            type: 'googleResult',
            inputValue: item.description
          };
        }),

        ...this.state.geocodingResults.map(item => {
          return {
            ...item,
            type: 'geocodingResult',
            description: item.formatted_address,
            inputValue: item.formatted_address
          };
        })
      );
    }

    return uniqBy(allTheItems, item => {
      return item.place_id;
    });
  }

  placePredictionsCallback(predictions) {
    if (!this._isMounted) return;
    this.setState({
      googleResults: predictions || [],
      geocodingResults: []
    });

    if (!predictions || predictions.length === 0) {
      this.setState({ loading: true });
      this.geocodingTimeout = setTimeout(this.performGeocode, 500);
    }

    // This is here to automatically use the first result from google if
    // the client component received address query params but no place id.
    // It allows the caller to have a fully initialized version of the input.
    if (
      this.props.useFirstAddressResult &&
      predictions.length > 0 &&
      this.state.unedited
    ) {
      this.onSelect(this.props.inputValue, predictions[0], false);
    }
  }

  performGeocode() {
    this.geocoder.geocode(
      { address: this.state.inputValue },
      this.geocoderCallback
    );
  }

  geocoderCallback(results) {
    this.setState({ loading: false });
    this.setState({ geocodingResults: results || [] });
  }

  filterSavedLocationsByQuery(locationsList, query) {
    if (isEmpty(locationsList)) {
      return [];
    }

    return filter(locationsList, item => {
      if (
        (item?.saved_record?.type === 'customer' ||
          item?.saved_record?.type === 'contact') &&
        fuzzySearch(query, item.saved_record?.contactCustomer)
      ) {
        return true;
      }

      if (
        item.description &&
        item.description.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return true;
      }

      if (
        item.label &&
        item.label.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return true;
      }

      return false;
    });
  }

  filterLocationsByQuery(locationsList, query) {
    return filter(locationsList, item => {
      if (
        item.description &&
        item.description.toLowerCase().indexOf(query.toLowerCase()) > -1
      ) {
        return true;
      }

      return false;
    });
  }

  onChange(e) {
    const inputValue = e.target.value;
    this.setState({ inputValue: inputValue, unedited: false });
    clearTimeout(this.geocodingTimeout);

    if (this.props.onChange) {
      this.props.onChange();
    }

    // handle the case where the input is empty, we want to prefill with
    // recent and saved locations
    if (!inputValue) {
      this.setState({
        googleResults: [],
        savedLocations: this.props.savedLocations,
        recentLocations: this.props.recentLocations
      });
      return;
    }

    if (this.props.asyncResolver) {
      this.props.asyncResolver(this.runQueries, inputValue);
    } else {
      this.runQueries(inputValue);
    }
  }

  runQueries(inputValue) {
    if (this.props.alwaysShowSaved) {
      this.setState({
        savedLocations: this.props.savedLocations
      });
    } else {
      this.setState({
        savedLocations: this.filterSavedLocationsByQuery(
          this.props.savedLocations,
          inputValue
        ),
        recentLocations: this.filterLocationsByQuery(
          this.props.recentLocations,
          inputValue
        )
      });
    }

    this.autocompleteService.getPlacePredictions(
      {
        input: inputValue,
        componentRestrictions: this.props.componentRestrictions ?? {
          country: ['US', 'CA', 'MX']
        },
        sessionToken: this.state.sessionToken
      },
      this.placePredictionsCallback
    );
  }

  onSelect(value, item, isInitialLoad) {
    this.setState(
      {
        refresh: true,
        inputValue: item.manual_location
          ? `${renderCoordinatesString(item)} (near ${value})`
          : value
      },
      () => this.setState({ refresh: false })
    );

    let valuesToOverride = {};
    if (item.phone_number) {
      valuesToOverride.formatted_phone_number = item.phone_number;
    }
    if (item.default_notes) {
      valuesToOverride.default_notes = item.default_notes;
    }
    if (item.saved_location_label) {
      valuesToOverride.saved_location_label = item.saved_location_label;
    }
    if (item.manual_location) {
      valuesToOverride.manual_location = item.manual_location;
    }
    if (item.saved_record) {
      valuesToOverride.saved_record = item.saved_record;
    }

    if (item.notes) {
      this.props.handleRecentLocationNotes(item.notes ?? null);
    }

    if (
      item.saved_record &&
      (isEmpty(item.description) || isNil(item.description))
    ) {
      valuesToOverride.no_location = true;

      const resultWithMergedValues = Object.assign({}, item, valuesToOverride);

      return this.props.onSelect(this.state.inputValue, resultWithMergedValues);
    }

    this.placesService.getDetails(
      {
        fields: [
          'address_component',
          'adr_address',
          'formatted_address',
          'geometry',
          'icon',
          'name',
          'photo',
          'place_id',
          'scope',
          'type',
          'url',
          'vicinity',
          'formatted_phone_number',
          'international_phone_number',
          'website'
        ],
        placeId: item.place_id,
        sessionToken: this.state.sessionToken
      },
      result => {
        this.setState({
          sessionToken: new google.maps.places.AutocompleteSessionToken()
        });
        // We merge the results from the places service with any relevant info
        // that was passed with the input field
        const resultWithMergedValues = Object.assign(
          {},
          result,
          valuesToOverride
        );

        this.props.onSelect(
          this.state.inputValue,
          resultWithMergedValues,
          isInitialLoad
        );
      }
    );

    if (this.props.clearInputValue) {
      this.setState({ inputValue: '' });
    }
  }

  renderItem(item, isHighlighted) {
    const highlightedClass = isHighlighted ? 'active' : '';
    const itemKey = item.place_id
      ? item.place_id
      : item.saved_record
      ? `saved_record-${item.saved_record?.id}`
      : item.toString();
    return (
      <div
        className={`autocomplete-menu-item autocomplete-menu-selectable ${highlightedClass}`}
        key={itemKey}
      >
        {this.renderItemContents(item)}
      </div>
    );
  }

  renderItemContents(item) {
    switch (item.type) {
      case 'googleResult':
        return this.renderGoogleItem(item);
      case 'savedLocation':
        return this.props.savedLocationComponent
          ? this.renderCustomSavedLocationComponent(item, this.state.inputValue)
          : this.renderSavedLocationItem(item);
      case 'recentLocation':
        return this.renderRecentLocationItem(item);
      case 'geocodingResult':
        return this.renderGeocodingItem(item);
    }
  }

  renderCustomSavedLocationComponent(item, inputValue) {
    return (
      <this.props.savedLocationComponent
        item={item}
        inputValue={inputValue}
        descriptionOnly={this.props.savedLocationComponentDescriptionOnly}
      />
    );
  }

  renderGoogleItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon" />
        <div className="autocomplete-menu-item-contents">
          <span>
            {stringOffsetReplace(
              item.description,
              item.matched_substrings,
              (match, i) => {
                return <strong key={match + i}>{match}</strong>;
              }
            )}
          </span>
        </div>
      </div>
    );
  }

  renderGeocodingItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon" />
        <div className="autocomplete-menu-item-contents">
          <div>{item.description}</div>
        </div>
      </div>
    );
  }

  renderSavedLocationItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon">
          <i className="glyphicon glyphicon-bookmark" />
        </div>
        <div className="autocomplete-menu-item-contents">
          {item.manual_location && renderCoordinatesString(item, true)}
          {renderWithHighlights(item.description, this.state.inputValue)}
          <div>
            <small className="text-muted">
              {renderWithHighlights(item.label, this.state.inputValue)}
            </small>
          </div>
        </div>
      </div>
    );
  }

  renderRecentLocationItem(item) {
    return (
      <div>
        <div className="autocomplete-menu-item-icon">
          <i className="icon icon-history" />
        </div>
        <div className="autocomplete-menu-item-contents">
          <span>
            {item.manual_location && renderCoordinatesString(item, true)}
            {renderWithHighlights(item.description, this.state.inputValue)}
          </span>
        </div>
      </div>
    );
  }

  renderSpinner() {
    return (
      <div className="autocomplete-menu-item">
        <div className="autocomplete-menu-item-icon">
          <i className="icon icon-spin1 animate-spin" />
        </div>
      </div>
    );
  }

  renderMenu(items) {
    // Return empty div if there's nothing to see
    if (!items || items.length < 1) {
      return <div />;
    }

    return (
      <div className="autocomplete-menu">
        {this.state.loading ? this.renderSpinner() : null}
        {items}
        <div className="text-center">
          <img
            className="autocomplete-googleimage inline"
            src={poweredByGoogle}
            alt=""
          />
        </div>
      </div>
    );
  }

  // Overrides the focus event of the input element, so that we can manually
  // trigger a change.  Otherwise the autocomplete shows the default results
  // until user starts typing
  onFocus(e) {
    this.onChange(e);
  }

  onBlur(e) {
    // Run an additional passed-in function if provided
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }

    // tell the caller that nothing was selected
    this.props.onSelect(this.state.inputValue, undefined);
  }

  render() {
    return !this.props.readOnly && !this.state.refresh ? (
      <Autocomplete
        getItemValue={item => {
          return item.inputValue;
        }}
        items={this.items()}
        renderItem={this.renderItem}
        ref={this.inputRef}
        inputProps={{
          className: this.props.inputClasses,
          id: this.props.inputId,
          name: this.props.inputName,
          placeholder: this.props.placeholder,
          onFocus: this.onFocus,
          onBlur: this.onBlur,
          onInvalid: this.props.onInvalid,
          required: this.props.required
        }}
        // Override the autoComplete attribute to something
        renderInput={props => {
          return (
            <div className="flex items-center">
              <input
                {...props}
                autoComplete={this.props.autoCompleteValue || 'off'}
              />
              {this.props.onManualLocation && (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={this.props.onManualLocation}
                  onKeyDown={e => {
                    if (e.key === 'Enter') this.props.onManualLocation();
                  }}
                  className="cx_i cx_i--m cx_i--gps cursor-pointer cx_l--margin-left-12"
                />
              )}
            </div>
          );
        }}
        wrapperStyle={{}}
        wrapperProps={{ className: 'autocomplete' }}
        value={this.state.inputValue}
        onChange={this.onChange}
        onSelect={this.onSelect}
        renderMenu={this.renderMenu}
        autoHighlight={true}
        selectOnBlur={true}
      />
    ) : (
      <input
        className={this.props.inputClasses}
        id={this.props.inputId}
        name={this.props.inputName}
        value={this.state.inputValue}
        placeholder={this.props.placeholder}
        readOnly={true}
      />
    );
  }
}

LocationAutocomplete.propTypes = {
  savedLocationComponent: PropTypes.elementType,
  savedLocationComponentDescriptionOnly: PropTypes.bool,
  alwaysShowSaved: PropTypes.bool,
  asyncResolver: PropTypes.func,
  autoCompleteValue: PropTypes.string,
  readOnly: PropTypes.bool,
  initialPlaceId: PropTypes.string,
  inputClasses: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  inputRef: PropTypes.object,
  inputValue: PropTypes.string,
  clearInputValue: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onInvalid: PropTypes.func,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  recentLocations: PropTypes.array,
  onlySavedLocations: PropTypes.bool,
  required: PropTypes.bool,
  savedLocations: PropTypes.array,
  useFirstAddressResult: PropTypes.bool,
  handleRecentLocationNotes: PropTypes.func,
  onManualLocation: PropTypes.func,
  value: PropTypes.object,
  componentRestrictions: PropTypes.object
};

export default LocationAutocomplete;
