import { find, includes, join, compact } from 'lodash';

const lookup = (place, key) => {
  const result = find(place.address_components, component => {
    return includes(component.types, key);
  });

  // return an empty object if not found so we can still call methods on it
  // and not get errors.
  return result || {};
};

export default place => {
  let resultAddress = {};

  resultAddress['street_number'] = lookup(place, 'street_number').short_name;
  resultAddress['street_name'] = lookup(place, 'route').short_name;

  resultAddress['neighborhood'] = lookup(place, 'neighborhood').short_name;

  resultAddress['state'] = lookup(
    place,
    'administrative_area_level_1'
  ).short_name;

  // Use google's "locality" and fallback to "administrative_area_level_3"
  // (which is usually something like a township)
  let city =
    lookup(place, 'locality').short_name ||
    lookup(place, 'administrative_area_level_3').short_name ||
    lookup(place, 'administrative_area_level_4').short_name ||
    lookup(place, 'administrative_area_level_5').short_name ||
    lookup(place, 'neighborhood').short_name;

  // per https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
  // "From Brooklyn and other parts of New York City do not include the city as part of the address.
  // They use sublocality_level_1 instead."
  if (city === undefined && resultAddress['state'] === 'NY') {
    city = lookup(place, 'sublocality_level_1').short_name;
  }

  resultAddress['city'] = city;

  resultAddress['zip'] = lookup(place, 'postal_code').short_name;
  resultAddress['country'] = lookup(place, 'country').short_name;

  const street = join(
    compact([resultAddress.street_number, resultAddress.street_name]),
    ' '
  );
  if (place.name !== street) {
    resultAddress['name'] = place.name;
  }

  resultAddress['phone_number'] = place.formatted_phone_number;
  resultAddress['street'] = street;

  // https://dispatchit.atlassian.net/browse/CA-2186
  if (resultAddress.country === 'PR' && !resultAddress.street) {
    resultAddress['street'] = lookup(place, 'plus_code').short_name;
  }

  return resultAddress;
};
