import React from 'react';
import PropTypes from 'prop-types';

const BoxTruck = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M5.3 15.8c-.7 0-1.2-.2-1.7-.6-.4-.4-.7-1-.7-1.6H1.3V3h12.6v3h1.9l2.9 3.8v3.8H17c0 .6-.3 1.2-.7 1.6-.5.4-1 .6-1.7.6s-1.2-.2-1.7-.6c-.4-.4-.7-1-.7-1.6H7.7c0 .6-.3 1.2-.7 1.6-.5.4-1 .6-1.7.6Zm0-1.3c.3 0 .5-.1.8-.3.2-.2.3-.5.3-.8a1 1 0 0 0-.3-.8 1 1 0 0 0-.8-.3 1 1 0 0 0-.8.3 1 1 0 0 0-.3.8c0 .3.1.6.3.8.2.2.5.3.8.3ZM3 11.9h.4l.8-.7a2 2 0 0 1 2 0l.7.7h5.2V4.8H3v7Zm11.6 2.6c.3 0 .5-.1.8-.3.2-.2.3-.5.3-.8a1 1 0 0 0-.3-.8 1 1 0 0 0-.8-.3 1 1 0 0 0-.8.3 1 1 0 0 0-.3.8c0 .3.1.6.3.8.2.2.5.3.8.3Zm-.8-4h3l-2-2.8h-1v2.8Z" />
    </svg>
  );
};

BoxTruck.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default BoxTruck;
