import React from 'react';
import PropTypes from 'prop-types';

const ClockFilled = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8.7 8.3V4.8a.6.6 0 0 0-.6-.6.6.6 0 0 0-.6.6V8.7l.1.2 2.9 3 .4.2c.2 0 .4 0 .5-.2l.2-.4c0-.2 0-.4-.2-.5L8.7 8.3ZM8 16.5c-1 0-2.1-.2-3.1-.6A8.1 8.1 0 0 1 0 8.5c0-1 .2-2.1.6-3.1A8.1 8.1 0 0 1 8 .5c1 0 2.1.2 3.1.6A8.1 8.1 0 0 1 16 8.5c0 1-.2 2.1-.6 3.1A8.1 8.1 0 0 1 8 16.5Z" />
    </svg>
  );
};

ClockFilled.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ClockFilled;
