import React from 'react';
import PropTypes from 'prop-types';

const CloseThin = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 17"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7.8c.4.5.4 1.2 0 1.7L2 16.2a1.1 1.1 0 0 1-1.7-1.7L14 .8c.5-.4 1.2-.4 1.7 0Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.3.8C.8.4 1.5.4 2 .8l13.7 13.7a1.1 1.1 0 0 1-1.7 1.7L.3 2.5C0 2 0 1.3.3.8Z"
      />
    </svg>
  );
};

CloseThin.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default CloseThin;
