import React from 'react';
import PropTypes from 'prop-types';

const NewWindow = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M2.4 4a.8.8 0 0 0-.8.8v8.8a.8.8 0 0 0 .8.8h8.8a.8.8 0 0 0 .8-.8V8.8a.8.8 0 0 1 1.6 0v4.8a2.4 2.4 0 0 1-2.4 2.4H2.4A2.4 2.4 0 0 1 0 13.6V4.8a2.4 2.4 0 0 1 2.4-2.4h4.8a.8.8 0 1 1 0 1.6H2.4ZM9.6.8c0-.4.4-.8.8-.8h4.8c.4 0 .8.4.8.8v4.8a.8.8 0 0 1-1.6 0v-4h-4a.8.8 0 0 1-.8-.8Z" />
      <path d="M15.8.2c.3.3.3.9 0 1.2L7 10.2A.8.8 0 0 1 5.8 9L14.6.2c.3-.3.9-.3 1.2 0Z" />
    </svg>
  );
};

NewWindow.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default NewWindow;
