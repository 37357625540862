import React from 'react';
import PropTypes from 'prop-types';

const Clock = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m11.9 13.5 1.2-1.2L10 9.1V5.2H8.3v4.6l3.6 3.7ZM9 18.2A8.4 8.4 0 0 1 3 15.6 8.8 8.8 0 0 1 .4 9.5 8.4 8.4 0 0 1 3 3.4 9 9 0 0 1 9 .8a9 9 0 0 1 8 5.3c.5 1 .7 2.2.7 3.4a9 9 0 0 1-5.3 8c-1 .5-2.2.7-3.4.7Zm0-2c1.8 0 3.4-.6 4.8-1.9 1.3-1.3 2-3 2-4.8 0-1.9-.7-3.5-2-4.8-1.4-1.3-3-2-4.8-2-1.9 0-3.5.7-4.8 2-1.3 1.3-2 3-2 4.8 0 1.9.7 3.5 2 4.8 1.3 1.3 3 2 4.8 2Z" />
    </svg>
  );
};

Clock.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Clock;
