import React from 'react';
import PropTypes from 'prop-types';

const Trash = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.636c0-.401.326-.727.727-.727h13.091a.727.727 0 0 1 0 1.455H.728A.727.727 0 0 1 0 3.636Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.818 1.455a.727.727 0 0 0-.727.727v.727h4.364v-.727a.727.727 0 0 0-.728-.727H5.818Zm5.091 1.454v-.727A2.182 2.182 0 0 0 8.727 0H5.818a2.182 2.182 0 0 0-2.182 2.182v.727H2.182a.727.727 0 0 0-.727.727v10.182A2.182 2.182 0 0 0 3.636 16h7.273a2.182 2.182 0 0 0 2.182-2.182V3.636a.727.727 0 0 0-.727-.727h-1.455Zm-8 1.455v9.454a.727.727 0 0 0 .727.728h7.273a.727.727 0 0 0 .727-.728V4.364H2.91Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.818 6.545c.402 0 .727.326.727.728v4.363a.727.727 0 0 1-1.454 0V7.273c0-.402.326-.728.727-.728ZM8.727 6.545c.402 0 .728.326.728.728v4.363a.727.727 0 0 1-1.455 0V7.273c0-.402.326-.728.727-.728Z"
      />
    </svg>
  );
};

Trash.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Trash;
