import React from 'react';
import PropTypes from 'prop-types';

const Info = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10 2.5a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM.8 10a9.2 9.2 0 1 1 18.4 0A9.2 9.2 0 0 1 .8 10Z" />
      <path d="M10 9.2c.5 0 .8.3.8.8v3.3a.8.8 0 0 1-1.6 0V10c0-.5.3-.8.8-.8Z" />
      <path d="M10.8 6.7a.8.8 0 1 1-1.6 0 .8.8 0 0 1 1.6 0Z" />
    </svg>
  );
};

Info.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Info;
