import React from 'react';
import PropTypes from 'prop-types';

const LoadingSpinner = props => {
  const { className, customClass } = props;

  return (
    <div
      className={`loading-spinner ${(className || customClass) ?? ''}`}
    ></div>
  );
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  customClass: PropTypes.string // customClass prop is deprecated; use className instead
};

export default LoadingSpinner;
