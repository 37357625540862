import React from 'react';
import PropTypes from 'prop-types';

const Receipt = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 19"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M.8 18.5V.5L2.2 2 3.4.6l1.3 1.3L6.1.6l1.3 1.3L8.7.6 10 1.9 11.3.6l1.3 1.3L14 .6l1.3 1.3L16.6.6v17.9l-1.3-1.3-1.3 1.3-1.4-1.3-1.3 1.3-1.3-1.3-1.3 1.3-1.3-1.3L6 18.5l-1.3-1.3-1.3 1.3L2 17.2.8 18.5ZM4 13.4h9.6v-1.5H4v1.5Zm0-3.2h9.6V8.8H4v1.4Zm0-3.1h9.6V5.6H4V7Zm-1.2 8.3h12V3.6h-12v11.8Z" />
    </svg>
  );
};

Receipt.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Receipt;
