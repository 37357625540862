import React from 'react';
import PropTypes from 'prop-types';

const ArrowUp = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8.7.3a1 1 0 0 0-1.4 0l-7 7a1 1 0 0 0 1.4 1.4L7 3.4V15a1 1 0 1 0 2 0V3.4l5.3 5.3a1 1 0 1 0 1.4-1.4l-7-7Z" />
    </svg>
  );
};

ArrowUp.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ArrowUp;
