import React from 'react';
import PropTypes from 'prop-types';

const AddOn = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M2.8 7 7 0l4.3 7H2.8Zm8.7 8.7c-1 0-1.8-.3-2.5-1s-1-1.5-1-2.5.3-1.7 1-2.4 1.5-1 2.5-1 1.7.3 2.4 1 1 1.5 1 2.4c0 1-.3 1.8-1 2.5s-1.5 1-2.4 1ZM0 15.2v-6h6v6H0Z" />
    </svg>
  );
};

AddOn.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default AddOn;
