import React from 'react';
import PropTypes from 'prop-types';

const CardboardBox = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 18"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m.7 6 6.9 4.5v7.2L.7 13.1V6ZM15.3 6l-6.9 4.4v7.3l6.9-4.6V6ZM11.8 3.2 5 7.7l3 2 6.8-4.5-3-2ZM8.2.6 1 5.2l3.2 2 6.9-4.6-3-2Z" />
    </svg>
  );
};

CardboardBox.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default CardboardBox;
