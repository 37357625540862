import React from 'react';
import PropTypes from 'prop-types';

const PedestrianCircle = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10.1 14.7v-3l-.9-1-.4 2.2-3.1-.6.1-.9 2.3.5.8-4-1.2.5v1.3h-.9v-2l2.7-.9h.6c.2.1.3.2.4.5a2.8 2.8 0 0 0 2 1.6v.8c-.2 0-.6 0-1-.3-.5-.3-1-.6-1.3-1l-.3 1.8 1 1.2v3.3h-.8Zm.3-8.3c-.2 0-.3-.1-.5-.3a.7.7 0 0 1-.2-.5c0-.2 0-.4.2-.5.1-.2.3-.2.5-.2s.4 0 .6.2l.2.5-.2.5-.6.3Zm-1 11.8a8.5 8.5 0 0 1-6.2-2.5 8.7 8.7 0 1 1 6.2 2.5Zm0-2c1.9 0 3.5-.6 4.8-1.9 1.3-1.3 2-3 2-4.8 0-1.9-.7-3.5-2-4.8-1.3-1.3-3-2-4.8-2-1.9 0-3.5.7-4.8 2-1.3 1.3-2 3-2 4.8 0 1.9.7 3.5 2 4.8 1.3 1.3 3 2 4.8 2Z" />
    </svg>
  );
};

PedestrianCircle.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default PedestrianCircle;
