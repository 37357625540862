import React from 'react';
import PropTypes from 'prop-types';

const PricingV2 = ({ className = '', fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44248 20.4956L12 24L15.5575 20.4956H20.4956V15.5575L24 12L20.4956 8.44248V3.50442H15.5575L12 0L8.44248 3.50442H3.50442V8.44248L0 12L3.50442 15.5575V20.4956H8.44248ZM11.1658 18.0889V20H12.877V18.1333C13.7611 18 14.5027 17.6593 15.1016 17.1111C15.7005 16.563 16 15.763 16 14.7111C16 13.8667 15.7469 13.1222 15.2406 12.4778C14.7344 11.8333 13.9893 11.363 13.0053 11.0667C11.9501 10.7556 11.2086 10.4593 10.7807 10.1778C10.3529 9.8963 10.139 9.51111 10.139 9.02222C10.139 8.59259 10.303 8.23333 10.631 7.94444C10.959 7.65556 11.4367 7.51111 12.0642 7.51111C12.6061 7.51111 13.0339 7.62963 13.3476 7.86667C13.6613 8.1037 13.9037 8.45926 14.0749 8.93333L15.6578 8.22222C15.4439 7.61481 15.1052 7.1 14.6417 6.67778C14.1783 6.25556 13.59 5.98519 12.877 5.86667V4H11.1658V5.86667C10.5383 5.94074 9.92513 6.24444 9.3262 6.77778C8.72727 7.31111 8.42781 8.05926 8.42781 9.02222C8.42781 9.82222 8.65597 10.5 9.1123 11.0556C9.56863 11.6111 10.41 12.0889 11.6364 12.4889C12.7772 12.8741 13.5045 13.2185 13.8182 13.5222C14.1319 13.8259 14.2888 14.237 14.2888 14.7556C14.2888 15.3333 14.0856 15.7593 13.6791 16.0333C13.2727 16.3074 12.7772 16.4444 12.1925 16.4444C11.508 16.4444 10.9554 16.2593 10.5348 15.8889C10.1141 15.5185 9.79679 14.9778 9.58289 14.2667L8 14.9333C8.25668 15.8519 8.65954 16.563 9.20856 17.0667C9.75758 17.5704 10.41 17.9111 11.1658 18.0889Z"
      />
    </svg>
  );
};

PricingV2.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default PricingV2;
