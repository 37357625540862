import React from 'react';
import PropTypes from 'prop-types';

const ChevronLeft = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M11.8.3c.5.5.5 1.2 0 1.7l-6 6 6 6a1.1 1.1 0 1 1-1.6 1.7L3.3 8.8c-.4-.4-.4-1.2 0-1.6L10.2.3c.4-.4 1.2-.4 1.6 0Z" />
    </svg>
  );
};

ChevronLeft.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ChevronLeft;
