import React from 'react';
import PropTypes from 'prop-types';

const Help = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.5a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13ZM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2 5.1a1.5 1.5 0 0 0-1.6 1 .7.7 0 1 1-1.4-.5 3 3 0 0 1 5.7 1c0 .7-.5 1.3-.9 1.7a5 5 0 0 1-1.3 1v.2a.7.7 0 0 1-1.5 0v-.2c0-.7.4-1.2 1-1.4l.8-.6c.3-.3.4-.5.4-.8a1.5 1.5 0 0 0-1.2-1.4Z"
      />
      <path d="M8.7 11.6a.7.7 0 1 1-1.4 0 .7.7 0 0 1 1.4 0Z" />
    </svg>
  );
};

Help.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default Help;
