import React from 'react';
import PropTypes from 'prop-types';

const ChevronUp = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M15.665 12.665a1.143 1.143 0 0 1-1.616 0L8 6.616l-6.049 6.05a1.143 1.143 0 0 1-1.616-1.617l6.857-6.857a1.143 1.143 0 0 1 1.616 0l6.857 6.857c.447.446.447 1.17 0 1.616Z" />
    </svg>
  );
};

ChevronUp.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ChevronUp;
