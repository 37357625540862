import { flatten } from 'lodash';

// Splits a string and applies whichever function you pass to it, for string
// match highlighting.  The offset data is expected to be in the format as
// provided by google in their AutocompleteService APIs, for string match
// highlighting.
//
// It is formatted like this:
//   [
//     { offset: 0, length: 3 },
//     { offset: 5, length: 2 }
//   ]
export default (string, offsets, fn) => {
  const chunks = splitStringByOffsets(string, offsets);
  return applyFunctionToOddEntries(chunks, fn);
};

const splitStringByOffsets = (string, offsets) => {
  // First flatten the offsets and translate into offset for beginning and end
  // instead of beginning and length
  const barrierOffsets = flatten(
    offsets.map(item => {
      return [item.offset, item.offset + item.length];
    })
  );

  // Grab chunks out of the string, defined by the offset locations
  let chunks = [];
  barrierOffsets.forEach((offset, index) => {
    let previousOffset = 0;
    if (index > 0) {
      previousOffset = barrierOffsets[index - 1];
    }
    chunks.push(string.slice(previousOffset, offset));
  });

  // Need to add the remaining string on the end
  chunks.push(string.slice(barrierOffsets[barrierOffsets.length - 1]));

  return chunks;
};

// Applies a function to the odd entries in an array. This is because the
// entries have been split into chunks that need a translation and those that
// don't, and they alternate.
const applyFunctionToOddEntries = (entries, fn) => {
  let curCharStart = 0;
  let curCharLen = 0;

  for (var i = 1; i < entries.length; i += 2) {
    curCharLen = entries[i].length;
    curCharStart += entries[i - 1].length;
    entries[i] = fn(entries[i], i, curCharStart);
    curCharStart += curCharLen;
  }

  return entries;
};
