import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8.7.3a1 1 0 0 0-1.4 1.4L12.6 7H1a1 1 0 0 0 0 2h11.6l-5.3 5.3a1 1 0 1 0 1.4 1.4l7-7c.4-.4.4-1 0-1.4l-7-7Z" />
    </svg>
  );
};

ArrowRight.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default ArrowRight;
