import React from 'react';
import PropTypes from 'prop-types';

const AddOnOutline = ({ className, fill = 'currentColor', size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill={fill}
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M3.8 8.5 8.7.7l4.8 7.8H3.8Zm9.8 9.8c-1.1 0-2-.4-2.8-1.1a3.8 3.8 0 0 1-1.1-2.8c0-1 .4-2 1.1-2.7.8-.8 1.7-1.1 2.8-1.1 1 0 2 .3 2.7 1 .8.8 1.2 1.8 1.2 2.8 0 1.1-.4 2-1.2 2.8-.7.7-1.6 1.1-2.7 1.1ZM.7 17.8V11h6.8v6.8H.7Zm12.9-1c.7 0 1.2-.2 1.7-.6.5-.5.7-1 .7-1.8 0-.6-.2-1.2-.7-1.7-.5-.4-1-.7-1.7-.7s-1.3.3-1.7.7c-.5.5-.7 1-.7 1.7s.2 1.3.7 1.8c.4.4 1 .6 1.7.6Zm-11.4-.5H6v-3.8H2v3.8Zm4.2-9.2H11L8.7 3.5 6.4 7Z" />
    </svg>
  );
};

AddOnOutline.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.number
};

export default AddOnOutline;
